import $http from '@/utils/axios/axiosUtils';

export function getCorporationList(params: any) {
  return $http({
    url: `/corporation/inquiry`,
    method: 'post',
    data: params,
  });
}
export function editCorporation(params: any, corporationId: string) {
  return $http({
    url: `/corporation/${corporationId}`,
    method: 'put',
    data: params,
  });
}
export function addCorporation(params: any) {
  return $http({
    url: `/corporation`,
    method: 'post',
    data: params,
  });
}
