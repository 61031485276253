
  import { defineComponent, reactive, toRefs, onBeforeMount } from 'vue';
  import { getCorporationList, addCorporation, editCorporation } from '@/api/corporation';
  import { ElMessageBox, ElMessage } from 'element-plus';
  import { IndexStateType } from './indexType';

  export default defineComponent({
    name: 'corporation',
    setup() {
      const state = reactive<IndexStateType>({
        searchForm: {
          name: '',
        },
        addForm: null,
        tableData: [],
        dialogTitle: '新增系统',
        dialogType: 'add',
        dialogEditVisible: false,
        editForm: {
          corporationId: '',
          name: '',
          description: '',
        },
        ruleForm: null,
      });

      const getData = async (params: any) => {
        const res: any = await getCorporationList(params);
        if (res.code === 0) {
          state.tableData = res.data.content;
        }
      };

      const handleReset = (name: string) => {
        const form: any = state[name];
        form.resetFields();
      };

      const handleAdd = () => {
        state.dialogEditVisible = true;
        state.dialogTitle = '新增主体';
        state.dialogType = 'add';
        state.editForm = {
          name: '',
          description: '',
        };
      };

      const updateData = () => {
        const params = {
          name: state.searchForm.name,
        };
        getData(params);
      };

      const handleSubmit = () => {
        const form: any = state.ruleForm;
        form.validate(async (valid: any) => {
          if (valid) {
            if (state.dialogType === 'add') {
              const params = { ...state.editForm };
              const res: any = await addCorporation(params);
              if (res.code === 0) {
                ElMessage({
                  message: '新建成功',
                  type: 'success',
                });
                updateData();
                state.dialogEditVisible = false;
                form.resetFields();
              } else {
                ElMessage({
                  message: res.msg,
                  type: 'warning',
                });
                form.resetFields();
              }
            } else {
              const params = { ...state.editForm };
              const res: any = await editCorporation(params, state.editForm.corporationId);
              if (res.code === 0) {
                ElMessage({
                  message: '编辑成功',
                  type: 'success',
                });
                updateData();
                state.dialogEditVisible = false;
                form.resetFields();
              } else {
                ElMessage({
                  message: res.msg,
                  type: 'warning',
                });
                form.resetFields();
              }
            }
          }
        });
      };

      const handleEdit = (row: any) => {
        console.log('row', row);
        state.dialogTitle = '编辑系统';
        state.dialogType = 'edit';
        state.dialogEditVisible = true;
        state.editForm = { ...row };
      };

      const handleClose = () => {
        state.dialogEditVisible = false;
      };

      onBeforeMount(() => {
        const params = {
          name: state.searchForm.name,
        };
        getData(params);
      });

      return {
        ...toRefs(state),
        handleAdd,
        updateData,
        getData,
        handleReset,
        handleClose,
        handleSubmit,
        handleEdit,
      };
    },
  });
